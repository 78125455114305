import Vue from 'vue';
// Sync router with store
import { sync } from 'vuex-router-sync';
import Vidle from 'v-idle';
import { createPinia, PiniaVuePlugin } from 'pinia';
import i18n from '@/i18n/index';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
// Import all global mixins
import '@/mixins';
// Plugins
import '@/styles/index.scss';
import './plugins';

import 'material-design-icons-iconfont/dist/material-design-icons.css';

Vue.use(Vidle);

// Sync store with router
sync(store, router);

Vue.config.productionTip = false;
/*
 Attach Vue dependencies the window object so ngVue can inject them as plugins.
 */
window.Vue = Vue;
window.store = store;
window.router = router;
window.i18n = i18n;

// only available during E2E tests
// Attach Vue dependencies the window object so that store may be used in cypress testing
if (window.Cypress) {
  window.store = store;
}

/*
  Attach App component to the window object where all the views from view folder are loaded,
  so components can be included with the ngVue library
 */
window.vueApp = App;
window.vuetify = vuetify;

Vue.use(PiniaVuePlugin);
const pinia = createPinia();

new Vue({
  router,
  store,
  i18n,
  vuetify,
  pinia,
  render: (h) => h(App),
}).$mount('#app');
