/* eslint-disable no-console */
export default {
  error(...args) {
    console.error(...args);
  },
  log(...args) {
    console.log(...args);
  },
  warn(...args) {
    console.warn(...args);
  },
};
